@import '../../../../../styles/variaveis.scss';

.linkCard{
    text-decoration: none;
}

.mainCard{
    margin-top: 2rem;
    max-width: calc(100% - 60%);
    padding-bottom: 2rem;
    box-shadow: $boxShadow;
    cursor: pointer;
    border-radius: .5rem;
    transition: all .3s;
    
    &:hover{
        transform: scale(1.1);
        transition: all .3s;
    }
}

.cardContainer{
    border: none;
    display: flex;
    align-items: center;
}

.iconContainer{
    background: $cardGradient;
    box-shadow: $boxShadow;
    border-radius: 50%;
    margin-top: 3rem;
    width: 40%;
}

.icon{
    color: $orangeSecondary;
    font-size: 3rem;
}

.text{
    color: $fontColor;
    font-family: 'PlusJakartaSans-Bold';
    font-size: 16px;
    padding-top: 3rem;
}

.modalTitle{
    color: $fontColor;
    font-family: 'PlusJakartaSans-Bold';
}

.iconOption{
    color: $orangeSecondary;
    font-size: 1.5rem;
    padding: .5rem;
    position: absolute;
    left: calc(100% - 15%);
    transition: .4s;
    
    &:hover{
        color: $orangePrimary;
        transition: .4s;
    }
}

@media(min-width: 300px) and (max-width: 600px){
    .iconContainer{
        width: 60%;
    }

    .text{
        text-align: center;
        font-size: 15px;
    }
}