@import '../../styles/variaveis.scss';

.inputText {
    border-radius: 21px;
    font-family: 'PlusJakartaSans-Light';
    border: 2px solid $yellowPrimary;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: $boxShadow;
    
    &::placeholder {
      color: $fontColor;
    }
  
    &:focus {
      border-color: $fontColor;
      outline: none;
      box-shadow: 0 0 0 3px rgba($fontColor, 0.5);
    }

    position: relative;
  }
  
  .inputWrapper {
    position: relative;
  
    input {
      width: 100%;
      padding-right: 2rem; // Garantir espaço suficiente para o ícone
    }
  
    .clearIcon {
      position: absolute;
      top: calc(100% - 75%);
      right: calc(100% - 93%);
      transform: translateY(-50%);
      cursor: pointer;
      color: $brownSecondary;
      font-size: 1.25rem;
    }
  }
  