@import '../../../../styles/variaveis.scss';

.navbarSection {
  width: 20%;
  transition: width 0.3s ease;
}

.container {
  display: flex;
  flex-direction: row;
}

.titleSection, .subTitleSection {
  margin-left: 20% !important;
}

.contentSection {
  flex: 1;
  margin-left: 23% !important;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease;

  @media(max-width: 480px) {
    margin-left: 10% !important;
  }
  @media(max-width: 764px) {
    margin-left: 15% !important;
  }
}

.cardContainer {
  width: 300px; /* Set fixed width */
  height: 350px; /* Set fixed height */
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden; /* Ensure content does not overflow */
}

.cardHeader {
  background: $cardGradient !important; /* Cor $yellowSecondary */
  color: #fff; /* Cor do texto no header */
  font-weight: bold;
  text-align: center;
  font-family: 'PlusJakartaSans-Medium';
}

.cardBody {
  display: flex;
  border: 4px solid $yellowSecondary;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden; /* Ensure no overflow of content */
  padding: 15px; /* Adjust padding as needed */
}

.bankLogo {
  max-width: 100px; /* Set a maximum width for bank logos */
  max-height: 35px; /* Set a maximum height for bank logos */
  width: auto;
  height: auto;
  margin-bottom: 10px; /* Space between the bank logo and other content */
}

.covenantLogo {
  max-width: 120px; /* Set a maximum width for covenant logos */
  max-height: 60px; /* Set a maximum height for covenant logos */
  width: auto;
  height: auto;
  margin-bottom: 10px;
}

.cardFooter {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.netAmount {
  font-size: 30px;
  font-weight: bold;
  color: $brownPrimary;
  margin-bottom: 20px;
}

.textAmount{
  color: $brownSecondary;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: left;
  font-family: 'PlusJakartaSans-Medium';
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.logoContainer{
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.buttonFirst{
  background: $yellowGradient;
  padding: .9rem;
  border: none;
  color: $brownPrimary;
  font-family: 'PlusJakartaSans-Bold';
  font-size: 16px;
  border-radius: .5rem;
  box-shadow: $boxShadow;
  transition: .3s;

  &:hover{
    transform: scale(1.1);
    transition: .3s;
    color: $brownPrimary;
  }
}

.buttonSecondary{
  background: $cardGradient;
  padding: .9rem 2rem;
  border: none;
  color: $brownPrimary;
  font-family: 'PlusJakartaSans-Bold';
  border-radius: .5rem;
  box-shadow: $boxShadow;
  font-size: 16px;
  
  &:hover{
    transform: scale(1.1);
    transition: .3s;
    color: $brownPrimary;
  }
}

.oportunityBage{
  padding: 0.9rem !important;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'PlusJakartaSans-Bold';
}
.badgeMargin{
  padding: 0.9rem !important;
  font-size: 0.9rem;
  background-color: $orangeSecondary !important;
  color: $orangePrimary;
  font-family: 'PlusJakartaSans-Bold';
}
.badgeCardMargin{
  padding: 0.9rem !important;
  font-size: 0.9rem;
  background-color: $orangeSecondary !important;
  color: $orangePrimary;
  font-family: 'PlusJakartaSans-Bold';
}
.badgeBenefitTypeNumber{
  background-color: $brownPrimary !important;
  font-size: 0.9rem;
  padding: 0.9rem !important;
  font-family: 'PlusJakartaSans-Bold';
}