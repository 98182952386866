@import '../../../../styles/variaveis.scss';

/* General container styles */
.container {
    display: flex;
    flex-direction: row;
}

/* Navbar section styles */
.navbarSection {
    width: 20%;
    transition: width 0.3s ease;
}

/* Content section styles */
.contentSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s ease;
}

.sideBarOpen {
    margin-left: 20%;
}

.sideBarClose {
    margin-left: 10%;
}

/* Custom checkbox styles */
.selectAllCheckbox {
    .form-check-input {
        position: relative;
        width: 1.25rem;
        height: 1.25rem;
        appearance: none;
        background-color: $orangePrimary;
        border: 2px solid $orangePrimary;
        border-radius: 0.25rem;
        cursor: pointer;

        &:checked::before {
            content: '';
            display: block;
            width: 0.75rem;
            height: 0.75rem;
            background: white;
            border-radius: 0.15rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba($orangePrimary, 0.25);
        }
    }

    .form-check-label {
        font-size: 1.25rem;
        color: $orangePrimary;
        margin-left: 0.5rem;
    }
}

@media (max-width: 764px) {
    .navbarSection {
        width: 100%;
    }

    .contentSection {
        margin-left: 10% !important;
        width: 100%;
    }
}

@media (max-width: 570px) {
    .navbarSection {
        width: 100%;
    }

    .contentSection {
        width: 100%;
    }
}
