@import '../../styles/variaveis.scss';

.logoContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.titleSection, .subTitleSection{
  margin-left: 6%;
}

.layoutContainer {
  margin-left: 8%;
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 200px); /* Ajustar ao tamanho da viewport */
  padding: 20px;
}

.sidebar {
  flex-basis: 400px; /* Largura fixa para a sidebar */
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: $lightPrimary;
  padding: 20px;
  border-radius: 10px;
}

.profileContainer {
  margin-bottom: 30px;
}

.profileImage {
  width: 250px;
  height: 250px;
}

.tabButtons {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tabButton {
  margin-bottom: 10px;
  width: 100%;
  background-color: $grayPrimary;
  color: $orangePrimary;
  border: none;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $orangePrimary;
    color: white;
  }

  &.active {
    background-color: $orangePrimary;
    color: white;
  }
}

.verticalLine {
  width: 2px;
  background-color: $orangeSecondary;
  margin: 0 20px;
}

.content {
  flex-grow: 1;
  padding: 20px;
  // background-color: $lightPrimary;
  border-radius: 10px;
  overflow-y: auto;
  margin-left: 10%;
}


/* Responsividade */
@media (max-width: 768px) {
  .layoutContainer {
    flex-direction: column;
  }

  .verticalLine {
    display: none;
  }

  .content {
    margin-top: 20px;
  }
  
  .sideBarClose{
    margin-left: -5% !important;
  }
}


@media (max-width: 1550px) {
  .titleSection, .subTitleSection{
    margin-left: 20% !important;
  }
  .layoutContainer{
    margin-left: 25% !important;
  }
}

@media (max-width: 1350px) {
  .titleSection, .subTitleSection{
    margin-left: 22% !important;
  }
  .layoutContainer{
    margin-left: 25% !important;
  }
}

@media (max-width: 1243px) {
  .titleSection, .subTitleSection{
    margin-left: 26% !important;
  }
  .layoutContainer{
    margin-left: 27% !important;
  }
}

@media (max-width: 1071px) {
  .titleSection, .subTitleSection{
    margin-left: 29% !important;
  }
  .layoutContainer{
    margin-left: 27% !important;
  }
}