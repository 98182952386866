@import '../../../styles/variaveis.scss';


.mainSection{
    margin-top: 20%;
    max-width: 40rem;
    margin-bottom: 3rem;
}

.icon{
    color: $orangePrimary;
    font-size: 25px;
    margin-left: calc(100% - 47%);
    cursor: pointer;
}

.cardsContainer{
    width: 60rem;
    gap: 2rem;
}

@media(min-width: 300px) and (max-width: 600px){
    .icon{
        margin-left: calc(100% - 60%);
    }
}
