@import '../../../../../styles/variaveis.scss';

.mainCard{
    margin-top: 2rem;
    max-width: calc(100% - 60%);
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    box-shadow: $boxShadow;
    cursor: pointer;
    transition: all .3s;
    
    &:hover{
        transform: scale(1.1);
        transition: all .3s;
    }
}

.iconContainer{
    background: $cardGradient;
    box-shadow: $boxShadow;
    border-radius: 50%;
    margin-top: 3rem;
    width: 40%;
}

.icon{
    color: $orangeSecondary;
    font-size: 3rem;
}

.text{
    color: $fontColor;
    font-family: 'PlusJakartaSans-Bold';
    font-size: 16px;
    padding-top: 3rem;
}

.optionContainer {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    box-shadow: $boxShadow;
    margin-bottom: .7rem;
    border-radius: .5rem;
    position: relative;
    transform: 3s;
    transition: all .5s;

    &:hover{
        box-shadow: 0px 4px 4px 0px $yellowPrimary;
        transition: all .5s;
        background: $grayPrimary;
        transform: 3s;
    }
}

.iconOptionContainer {
    background: $cardGradient;
    box-shadow: $boxShadow;
    border-radius: 50%;
    width: 3rem; 
    height: 3rem; 
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconOption {
    font-size: 1.5rem;
    color: $grayPrimary;
}

.modalHeader{
    color: $fontColor;
    font-family: 'PlusJakartaSans-Bold';
}

.textOption {
    font-size: 1.3rem;
    color: $brownSecondary; 
    font-family: 'PlusJakartaSans-Bold';
}

.checkbox {
    position: absolute;
    top: 1rem; 
    right: 0.5rem;
    transform: scale(1.3); 

    /* Remove o estilo padrão do checkbox */
    appearance: none;
    -webkit-appearance: none;

    background-color: $grayPrimary; 
    border:$border; 
    width: 1rem; 
    height: 1rem; 
    border-radius: 4px; 
    cursor: pointer; 

    &:checked {
        background-color: $orangePrimary; 
        text-align: center; 
        line-height: 1rem; 
        border: $orangePrimary;
    }
    
}

@media(min-width: 300px) and (max-width: 600px){
    .iconContainer{
        width: 60%;
    }

    .text{
        text-align: center;
        font-size: 15px;
    }
}