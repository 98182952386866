//paleta
$orangePrimary: #007f5b;
$orangeSecondary: #ffdd00;
$orangeDanger: #007f5b;
$yellowPrimary: #ffdd00;
$yellowSecondary: #F2B705;
$yellowDataItem: #F2B505;
$yellowOrange: #007f5b;

$whitePrimary: #fff;

//background colors
$grayPrimary: #F2F2F2;
$beigePrimary:#FEEECC;
$beigeSecondary:#FDDE9A;
$orangeBackground: #F8F1DD;

$brownPrimary: #007f5b;
$brownSecondary: #007f5b;

//GRADIENTES
$backgroundGradient: linear-gradient(135deg, $orangePrimary, #153B7F);
$underlineGradient: linear-gradient(to right, #ffdd00, #F2B705); 
$sideBarGradient: linear-gradient(to bottom, #007f5b 10%, #007f5b 60%);
$cardGradient: linear-gradient(to bottom, #007f5b, #007f5b); 
$yellowGradient: linear-gradient(to bottom, #FDDE9A 10%, #F2B705 70%); 
$loginGradient: linear-gradient(to bottom, $whitePrimary, rgba($beigePrimary, 0.8) 80%);

$border: 1px solid #ccc;
$boxShadow:  0px 4px 4px 0px #00000040;

//fontes 
$fontColor: #007f5b;

@font-face {
    font-family: 'PlusJakartaSans-Light';
    src: url(../assets/fonts/PlusJakartaSans-Light.ttf);
}

@font-face {
    font-family: 'PlusJakartaSans-Medium';
    src: url(../assets/fonts/PlusJakartaSans-Medium.ttf);
}

@font-face {
    font-family: 'PlusJakartaSans-Bold';
    src: url(../assets/fonts/PlusJakartaSans-Bold.ttf);
}
