@import '../../styles/variaveis.scss';

.underlineTitle {
    position: relative;
    font-family: 'PlusJakartaSans-Medium';
    display: inline-block;
    padding-bottom: 0.5rem;
    font-weight: bold;
    color: $brownPrimary;

 
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 600px;
      height: .3rem;
      background: $underlineGradient;
      border-radius: 2px;

    }
  }
