@import '../../../styles/variaveis.scss';

.buttonPrimary {
  background-color: $orangePrimary!important;
  color: white !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  border: none !important;
  border-radius: .5rem !important;
  padding: .6rem 1.5rem !important;
  font-family: 'PlusJakartaSans-Light' !important;
  cursor: pointer !important;
  margin-bottom: 1rem !important;
  transition: all .5s !important;

  &:hover {
    background-color: $orangePrimary !important;
    transition: all .5s !important;
  }

  &:disabled {
    background-color: $orangeSecondary !important;
    color: $whitePrimary !important;
    cursor: not-allowed !important;
    opacity: 0.65 !important;
  }
}
