@import '../../../../styles/variaveis.scss';

.lista{
    border-radius: 10px;
}

.listaItem{
    font-family: 'PlusJakartaSans-Light';
    font-size: 17px;
    color: $whitePrimary;
    border: none;
    background: $orangePrimary;
}
 