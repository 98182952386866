@import '../../styles/variaveis.scss';

.logoContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.titleSection, .subTitleSection{
  margin-left: 6%;
}

.layoutContainer {
  margin-left: 8%;
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 200px); /* Ajustar ao tamanho da viewport */
  padding: 20px;
}

.sidebar {
  flex-basis: 400px; /* Largura fixa para a sidebar */
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: $lightPrimary;
  padding: 20px;
  border-radius: 10px;
}

.sideBarOpen{
  margin-left: calc(100% - 80%);
}

.profileContainer {
  margin-bottom: 30px;
}

.profileImage {
  width: 250px;
  height: 250px;
}

.tabButtons {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tabButton {
  margin-bottom: 10px;
  width: 100%;
  background-color: $grayPrimary;
  color: $orangePrimary;
  border: none;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $orangePrimary;
    color: white;
  }

  &.active {
    background-color: $orangePrimary;
    color: white;
  }
}

.verticalLine {
  width: 2px;
  background-color: $orangeSecondary;
  margin: 0 20px;
}

.content {
  flex-grow: 1;
  padding: 20px;
  // background-color: $lightPrimary;
  border-radius: 10px;
  overflow-y: auto;
  margin-left: 10%;
}

/* Responsividade */
@media (max-width: 768px) {
  .layoutContainer {
    flex-direction: column;
  }

  .verticalLine {
    display: none;
  }

  .content {
    margin-top: 20px;
  }
}


@media (max-width: 1550px) {
  .layoutContainer{
    margin-left: 25% !important;
  }
}

@media (max-width: 1350px) {
  .layoutContainer{
    margin-left: 25% !important;
  }
}

@media (max-width: 1243px) {
  .layoutContainer{
    margin-left: 27% !important;
  }
}

@media (max-width: 1071px) {
  .titleSection, .subTitleSection{
    margin-left: 29% !important;
  }
  .layoutContainer{
    margin-left: 27% !important;
  }
}

.welcomeSection {
    text-align: center;
    margin-bottom: 20px;
    font-family: 'PlusJakartaSans-Medium';
  }
  
  .title {
    font-size: 1.5rem; /* Tamanho maior para o título */
    color: $brownPrimary; /* Cor do título */
    font-weight: bold; /* Negrito para o título */
  }
  
  .sectionTitle {
    font-size: 1.3rem; /* Tamanho do título das seções */
    color: $orangePrimary; /* Cor do título da seção */
    margin: 20px 0 10px; /* Margens para espaçamento */
    font-family: 'PlusJakartaSans-Medium'; /* Fonte do título da seção */
    border-bottom: 2px solid $yellowPrimary; /* Linha embaixo do título */
    padding-bottom: 5px; /* Espaçamento abaixo da linha */
  }
  
  .listGroup {
    margin-bottom: 30px; /* Margem abaixo das listas */
  }
  .bankLogo, .agreementLogo {
    width: 50px; /* Standardize logo width */
    height: 50px; /* Set a standard height */
    object-fit: contain; /* Ensure logos maintain aspect ratio */
    margin-right: 15px; /* Spacing between logo and text */
    margin-top: 10px;
}

.listItem {
    display: flex; /* Align items in a row */
    align-items: center; /* Center align items vertically */
    height: 80px; /* Set a fixed height for uniformity */
    padding: 10px 15px; /* Define padding for top/bottom and left/right */
    border: 1px solid #ddd; /* Optional: Add a border for better visual separation */
    margin-bottom: 10px; /* Space between list items */
    transition: background-color 0.3s; /* Optional: Add a hover effect */
    justify-content: space-between; /* Ensure items are spaced correctly */
}

.agreementName {
    font-family: 'PlusJakartaSans-Medium'; /* Fonte do nome do convênio */
    flex-grow: 1; /* Faz com que o nome do convênio ocupe o espaço disponível */
    text-align: right; /* Alinha o texto à direita */
}

.listItem:hover {
  background-color: #f8f9fa; /* Optional: Highlight on hover */
}


.bankName {
  font-family: 'PlusJakartaSans-Medium'; /* Fonte do nome do banco */
  flex-grow: 1; /* Faz com que o nome do banco ocupe o espaço disponível */
}

.bankAmount {
  font-family: 'PlusJakartaSans-Medium'; /* Fonte do valor */
  color: $orangePrimary; /* Cor do valor */
  font-weight: bold; /* Negrito para o valor */
}

.agreementName {
  font-family: 'PlusJakartaSans-Medium'; /* Fonte do nome do convênio */
  flex-grow: 1; /* Faz com que o nome do convênio ocupe o espaço disponível */
}

.actionButton {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: $orangePrimary;
  border: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $yellowPrimary; /* Muda a cor ao passar o mouse */
  }
}