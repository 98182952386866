@import '../../../../styles/variaveis.scss';


.modalGeral{
    border-radius: 25px !important;
}
.modalHeader{
    font-family: 'PlusJakartaSans-Bold';
    background-color: $yellowSecondary;
    color: white;
}
.modalBody{
    font-family: 'PlusJakartaSans-Light';
    background-color: $beigeSecondary;
    color: $brownSecondary;
}
.imageModal{
    width: 50%;
}
.rowLero{
    margin-top: -60px;
}
.floatingLabel {
    position: relative;
    margin-bottom: 1rem;

    input {
        width: 100%;
        padding: 0.95rem;
        font-size: 1rem;
        border: 1px solid $grayPrimary;
        border-radius: .5rem;
        color: $orangePrimary !important;
        transition: border-color 0.3s, box-shadow 0.3s;

        &:focus {
            border-color: $orangePrimary;
            box-shadow: 0 0 0 0.2rem rgba($orangePrimary, 0.25);
            outline: none;
        }

        &::placeholder {
            color: $orangePrimary !important;
        }
    }

    label {
        position: absolute;
        top: 50%;
        left: 0.75rem;
        font-size: 1rem;
        color: $orangePrimary;
        pointer-events: none;
        transition: 0.3s ease all;
        transform: translateY(-50%);
    }

    input:focus + label,
    input:not(:placeholder-shown) + label {
        top: -0.5rem;
        left: 0.75rem;
        font-size: 0.75rem;
        color: $orangePrimary;
        background: $whitePrimary;
        padding: 0 0.25rem;
    }
}