.navbarSection {
    float: left;
    width: 20%;
}

.contentSection{
    max-width: 100rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    margin-left: 120px;
}

.sideBarOpen{
    transition: all .5s;
    gap: 10rem;
    margin-left: 15rem;
}

.open{
    max-width: 40rem;
}

.close{
    max-width: 50rem;
}

.sideBarClose{
    max-width: 100rem;
    transition: all 5s;
    margin-left: 10rem !important;
}

img{
    margin-top: 40px;
}


@media (min-width: 800px) {
    .navbarSection {
        float: none;
        width: 100vh;
    }
}

@media(min-width: 300px) and (max-width: 600px){
    .contentSection, .navbarSection{
        width: 100%;
    }

    .img{
        max-width: 20rem;
    }

    .sideBarClose{
        margin-left: .3rem !important;
    }
}

@media(min-width: 650px){
    .contentSection, .navbarSection{
        width: 100%
    }

    .sideBarClose{
        margin-left: -8rem !important;
    }
}

@media(min-width: 700px) and (max-width:1400px){
    .sideBarOpen{
        margin-left: 5%;
    }

    .sideBarClose{
        display: flex;
        flex-direction: column;
    }

    .close{
        width: 100vh;
        margin-left: 20%;
    }
}

@media(min-width: 1500px){
    .sideBarOpen{
        margin-left: 17rem;
    }

    .sideBarClose{
        margin-left: calc(100% - 90%) !important;
    } 
}