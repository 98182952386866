@import '../../styles/variaveis.scss';

.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 15px 0;
  
    .paginationItem {
      cursor: pointer;
      padding: 10px 15px;
      margin: 0 3px;
      color: $orangePrimary !important;
      border: 1px solid $orangePrimary;
      border-radius: 4px;
      background-color: $whitePrimary;
      transition: background-color 0.3s, color 0.3s;
      
      &:hover {
        background-color: $orangePrimary;
        color: $whitePrimary !important;
        transition: background-color 0.3s, color 0.3s;
      }
    }
  
    .active {
      background-color: $orangePrimary;
      color: $whitePrimary !important;
      border: 1px solid $orangePrimary;
      z-index: 3;
    }
  }
  
  